<template>
    <div class="ivv-content">
        <div ref="content" :style="`transform: scale(1.0, 0.4);position: relative;margin-top: -${bottom}px`">
            <div class="theme">
                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315153911707.png"/>
                <div class="auth-logo">
                    <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315175014081.png"/>
                </div>
            </div>
            <div class="rank-list">
                <div class="rank-item" v-for="item in dataList">
                    <div class="ivv-title">
                        <img :src="item.titlePath"/>
                    </div>
                    <div class="ivv-list">
                        <template v-if="item.listId < 12">
                            <van-row class="ivv-header">
                                <van-col span="4"></van-col>
                                <van-col span="2"></van-col>
                                <van-col span="5">姓名</van-col>
                                <van-col span="6">订货业绩</van-col>
                                <van-col span="7">奖励</van-col>
                            </van-row>

                            <van-row class="ivv-user" v-for="(it,index) in item.list" key="index">
                                <van-col class="rank-num" span="3">
                                    <img v-if="index === 0"
                                         src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315155924144.png"/>
                                    <img v-else-if="index === 1"
                                         src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315160051738.png"/>
                                    <img v-else-if="index === 2"
                                         src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315160103097.png"/>
                                    <div class="ivv-serial" v-else>
                                        <span>No.</span>
                                        <div>{{index + 1}}</div>
                                    </div>
                                </van-col>
                                <van-col class="head-img" span="3">
                                    <img :src="it.headImg"/>
                                </van-col>
                                <van-col span="5">
                                    <div class="user-name ellipsis2">{{it.name}}</div>
                                    <div class="f-code">{{it.authCode}}</div>
                                </van-col>
                                <van-col class="performance" span="6">￥{{it.perf}}</van-col>
                                <van-col class="ivv-reward ellipsis2" span="7">{{it.prize}}</van-col>
                            </van-row>
                        </template>
                        <div class="ivv-list" v-else>
                            <van-row class="ivv-total">
                                <van-col span="12">
                                    共计:<span>{{item.totalQty}}人</span>
                                </van-col>
                                <van-col span="12">
                                    均奖:<span class="font-yellow">{{item.listId === 12 ?2:1}}个礼包</span>
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "SilverStarIndex",
    data() {
      return {
        // 巅峰榜 卓越榜 飞跃榜 突击榜
        titleList: ['http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315175038425.png', 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315175115769.png',
          'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315175137097.png'],
        dataList: [],
          bottom: 0
      }
    },
    created() {
      this.getList();
    },
    mounted() {
      document.documentElement.style.fontSize = 'calc(100vw / 1488 * 100)';
    },
    methods: {
      getList() {
        this.$toast.loading({message: '加载中...', overlay: true, duration: 0});
        this.get("/OnlyOnce/OfflinePKCompetition/GradeList?", {star: 2}, 1).then(res => {
          if (res.code === 1) {
            let list = res.response;
            let arr = this.titleList;
            list.forEach((item, index) => {
              item.titlePath = arr[index];
            });
            this.dataList = res.response;
              setTimeout(() => {
                  const dom = this.$refs.content
                  this.bottom = dom.clientHeight *0.6/2
              }, 200)
          }
          this.$toast.clear();
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .ivv-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100%;
        font-size: 4.26vw;
        overflow-y: auto;
        padding: 0 1.1rem;
        background-color: rgb(178,30,34);
        /*background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315155146191.png") no-repeat #b21e22;*/
        /*background-size: 100% 5%;*/

        .theme {
            position: relative;
            padding: 1.2rem 0 0.8rem;

            > img {
                width: 2.81rem;
            }

            .auth-logo {
                position: absolute;
                right: 0;
                bottom: 0;

                img {
                    width: 4.13rem;
                }
            }
        }


        .rank-list {
            .rank-item {
                margin-top: 0.7rem;
                border-radius: 0.12rem;
                background-color: #fff;

                .ivv-title {
                    height: 0.72rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315160703832.png") no-repeat;
                    background-size: 100% 100%;

                    img {
                        width: 1.46rem;
                    }
                }

                .ivv-list {
                    padding: 0 0.56rem;

                    .ivv-header {
                        line-height: 0.7rem;

                        .van-col {
                            font-size: 0.3rem;
                        }
                    }

                    .ivv-user {
                        display: flex;
                        align-items: center;
                        padding: 0.1rem 0;
                        border-bottom: 0.01rem solid #f1f1f1;

                        .rank-num {
                            > img {
                                width: 0.56rem;
                            }

                            .ivv-serial {
                                span {
                                    color: #fff;
                                    padding: 0.02rem 0.1rem;
                                    font-size: 0.1945rem;
                                    border-radius: 0.19rem;
                                    background-color: #cccccc;
                                }

                                div {
                                    font-size: 0.3889rem;
                                }
                            }
                        }

                        .head-img {
                            font-size: 0;

                            img {
                                width: 0.73rem;
                                height: 0.73rem;
                                border-radius: 50%;
                            }
                        }

                        .user-name {
                            color: #000;
                            font-size: 0.28rem;
                        }

                        .f-code {
                            color: #999;
                            font-size: 0.24rem;
                            padding-top: 0.1rem;
                        }

                        .performance {
                            color: #fd0303;
                            font-size: 0.409rem;
                        }

                        .ivv-reward {
                            color: #f9b022;
                            font-size: 0.364rem;
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    .ivv-total {
                        color: #666;
                        padding: 0.3rem 0;
                        font-size: 0.4rem;

                        span {
                            color: #000;
                        }

                        .font-yellow {
                            color: #f9b022;
                        }
                    }
                }

                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }

</style>